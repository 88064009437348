import React, { useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { SiteContext } from "./Main";
import { AuthContext } from "@parallelpublicworks/understory-auth";
import { Unauthorized } from "../components";
import SingleBody from "./SingleBody";
import { checkViewPermissions, isIframe } from "../utils";

function Single({ pageContext }) {
  const { nodesConfig, allVocabularyRefs, allContentTypeRefs, userData } =
    useContext(SiteContext);
  const authContext = React.useContext(AuthContext);
  const [{ isAuthenticated }] = authContext;
  const [privateContent, setPrivateContent] = React.useState(null);

  const is_frame = isIframe();

  React.useEffect(() => {
    if (isAuthenticated || is_frame) {
      setPrivateContent(pageContext);
    }
  }, [isAuthenticated, pageContext]);

  React.useEffect(() => {
    if(is_frame && typeof document !== 'undefined'){
      let root = document.getElementsByTagName( 'html' )[0];
      root.classList.add("is-iframe");
    }
  }, [])

  const entities = {};
  for (const an_include of pageContext.includes.fullEntities) {
    entities[an_include.id] = { ...an_include };
  }

  //Inject node data into relationships
  if (entities) {
    const entity_types = nodesConfig.map((config) => config.type);
    entities[pageContext.id] = pageContext;
    for (let [uuid, entity] of Object.entries(entities)) {
      if (entity.relationships) {
        for (let [rel_field_name, rel_info] of Object.entries(entity.relationships)) {
          if (rel_info.data) {
            if (Array.isArray(rel_info.data)) {
              for (const i in rel_info.data) {
                const a_rel_entity = rel_info.data[i];
                if (
                  a_rel_entity.type &&
                  entity_types.includes(a_rel_entity.type) &&
                  a_rel_entity.id &&
                  typeof entities[a_rel_entity.id] !== "undefined"
                ) {
                  entity.relationships[rel_field_name]["data"][i] = {
                    ...a_rel_entity,
                    ...entities[a_rel_entity.id],
                  };
                }
              }
            } else if (
              rel_info.data.type &&
              entity_types.includes(rel_info.data.type) &&
              rel_info.data.id &&
              typeof entities[rel_info.data.id] !== "undefined"
            ) {
              if (rel_field_name === "field_content_type") {
                const ctype = allContentTypeRefs[rel_info.data.id];
                if (ctype && ctype.type) {
                  entities[uuid].relationships[rel_field_name]["data"] = ctype.type;
                }
              } else if (rel_field_name === "field_taxonomy") {
                const vocab = allVocabularyRefs[rel_info.data.id];
                if (vocab && vocab.taxonomy) {
                  entities[uuid].relationships[rel_field_name]["data"] = vocab.taxonomy;
                }
              } else {
                entity.relationships[rel_field_name]["data"] = {
                  ...rel_info.data,
                  ...entities[rel_info.data.id],
                };
              }
            }
          }
        }
      }
    }
  }

  const excerpts = pageContext.includes ? pageContext.includes.listedEntities : null;
  let listedEntities;
  if (excerpts) {
    listedEntities = Object.keys(excerpts).map((uuid) => excerpts[uuid]);
  }
  if (!pageContext.isPrivate)
    return <SingleBody {...pageContext} listedEntities={listedEntities} doSEO />;
  if (privateContent === null || userData === null) return <CircularProgress />;
  if (pageContext.isPrivate) {
    if (
      pageContext.relationships?.field_access_group &&
      pageContext.relationships?.field_access_group.data &&
      Array.isArray(pageContext.relationships?.field_access_group.data)
    ) {
      const canView = checkViewPermissions(
        pageContext.relationships?.field_access_group.data.map((r) => r.id),
        userData
      );
      if (canView || is_frame) {
        return <SingleBody {...privateContent} listedEntities={listedEntities} />;
      } else {
        return <Unauthorized />;
      }
    } else {
      return <SingleBody {...privateContent} listedEntities={listedEntities} />;
    }
  }
}

export default Single;
